import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Pagination from '../Pagination';
import PostStyle2 from '../Post/PostStyle2';
import Div from '../Div';
import Sidebar from '../Sidebar.jsx';
import Spacing from '../Spacing';
import blogdetail from "../blogData.json"

const postData = [
  {
    thumb: '/images/blogs/3.png',
    title: 'How Does a CRM System Benefit a Business?',
    subtitle:  'Staying connected to customers in today digital environment is more necessary than ever, which is where Customer Relationship Management (CRM) comes into play. Consider CRM your central command centre for business relationships and an organisation tool designed to serve them better. But what benefits does CRM bring into our daily operations? In this article, we look into how CRM makes business life simpler, more effective, and more customer-oriented!',
    date: '07 Mar 2022',
    category: '',
    categoryHref: '/blog',
    href: '/blog/blog-details',
  },
  {
    thumb: '/images/blogs/55555.png',
    title: 'The Role of Content in Cultivating a Brand Community',
    subtitle:
      'Brand Communities can be defined as any group with an emotional tie to a brand, creating groups who feel an affinity toward it and its message. Content plays a pivotal role in forging these communities by cultivating an atmosphere that feels genuine and friendly. It creates an authentic sense of community among its audience members while building on existing brand affinities with each individual user—making for vibrant brand communities!',
    date: '05 Mar 2022',
    category: '',
    categoryHref: '/blog',
    href: '/blog/blog-details',
  },
  {
    thumb: '/images/blogs/4.png',
    title: 'Strategies for Establishing an Engaging Online Presence',
    subtitle:
      'A solid online presence has become essential in our digital era for businesses and individuals alike, whether entrepreneurs are running their businesses online, blogging their thoughts, creating trust with an audience online, etc. But how exactly can one differentiate themselves in such an overcrowded digital realm?',
    date: '04 Mar 2022',
    category: '',
    categoryHref: '/blog',
    href: '/blog/blog-details',
  },
];

export default function BlogPage() {
  pageTitle('Blog');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  return (
    <>
      <PageHeading
        title="Our Blog"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText=""
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-12">
            {postData.map((item, index) => (
              <Div key={index}>
                <PostStyle2
                  thumb={item.thumb}
                  title={item.title}
                  subtitle={item.subtitle}
                  date={item.date}
                  category={item.category}
                  categoryHref={item.categoryHref}
                  href={`${item.title}`}
                />
                {postData.length > index + 1 && <Spacing lg="95" md="60" />}
              </Div>
            ))}
            <Spacing lg="60" md="40" />
            {/* <Pagination /> */}
          </Div>
          {/* <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg="0" md="80" />
            <Sidebar />
          </Div> */}
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s create something <br />extraordinary together"
          btnText="Book A Discovery Call"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
