import React from 'react';
import Slider from 'react-slick'; // Import react-slick
import Div from '../Div';
import './logolist.scss';

const partnerLogos = [
    // {
    //     src: '/images/logos/247 logo-01.png',
    //     alt: 'Partner',
    // },
    {
        src: '/images/logos/Untitled-1.png',
        alt: 'Partner',
    },
    {
        src: '/images/logos/ezfify.png',
        alt: 'Partner',
    },
    {
        src: '/images/logos/hs.png',
        alt: 'Partner',
    },
    // {
    //     src: '/images/logos/reality logo-01.png',
    //     alt: 'Partner',
    // },
    {
        src: '/images/logos/salden.png',
        alt: 'Partner',
    },
    {
        src: '/images/logos/rsd.png',
        alt: 'Partner',
    },
    {
        src: '/images/logos/sippa.png',
        alt: 'Partner',
    },
    // {
    //     src: '/images/logos/uls logo-01.png',
    //     alt: 'Partner',
    // },
    // {
    //     src: '/images/logos/your talent logo-01.png',
    //     alt: 'Partner',
    // },
];

export default function LogoSlider() { 
    // Slider settings
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Div className="">
            <Slider {...settings}>
                {partnerLogos.map((partnerLogo, index) => (
                    <div className="d-flex justify-content-center" key={index}>
                        <img src={partnerLogo.src} alt={partnerLogo.alt} style={{height:'170px'}} loading='lazy'/>
                    </div>
                ))}
            </Slider>
        </Div>
    );
}
